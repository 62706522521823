import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import SupportLeftNav from "../../../components/TwoColumnLeftNav/SupportLeftNav";
import CatalogDownloadList from "../../../components/CatalogDownload/CatalogDownloadList";
import { LocaleContext } from "../../../components/layout";
import LocalizedLink from "../../../components/localizedLink";

const CatalogDownload = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { catalogDownload } = useTranslations();

	const CatalogList = [
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-2021all.pdf",
			title: "SK総合カタログ 2020～2021",
			catalog_list_info_page: "A4 154ページ",
			catalog_list_info_date: "掲載日：2020年2月3日",
			catalog_list_info_update: "※（2020年10月30日 最新版を掲載）",
			catalog_list_sk_link: "※ページごとはこちら",
			sk_link: "/support/catalog-download/sk/",
		},
		{
			alt: "防水型無線温度ロガー SK-320BTシリーズ",
			image: "/asset/catalog/SK-320BT-C.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-320BT-C.pdf",
			title: "防水型無線温度ロガー SK-320BTシリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "掲載日:2021年6月10日",
			catalog_list_info_update: "",
		},
		{
			alt: "快適ナビ CO2モニター SK-50CTH",
			image: "/asset/catalog/SK-50CTH.png",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-50CTH.pdf",
			title: "快適ナビ CO2モニター SK-50CTH",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日:2021年5月24日",
			catalog_list_info_update: "※2021年9月29日 最新版を掲載",
		},
		{
			alt: "熱中症予防キャンペーン2021",
			image: "/asset/catalog/2020_nettyu.png",
			link: "https://static.sksato.co.jp/corporate/catalog/nettyu.pdf",
			title: "熱中症予防キャンペーン2021",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日：2021年3月19日",
			catalog_list_info_update: "",
		},
		{
			alt: "防水型デジタル温度計 SK-270WPシリーズ",
			image: "/asset/catalog/SK-270WPseries.png",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-270WPseries.pdf",
			title: "防水型デジタル温度計 SK-270WPシリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "掲載日：2021年3月5日",
			catalog_list_info_update: "",
		},
		{
			alt: "冷蔵庫・冷凍庫、保管庫向け",
			image: "/asset/catalog/reizouko.png",
			link: "https://static.sksato.co.jp/corporate/catalog/reizouko.pdf",
			title: "冷蔵庫・冷凍庫、保管庫向け",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日：2021年3月5日",
			catalog_list_info_update: "",
		},
		{
			alt: "感染症・インフルエンザ対策2020",
			image: "/asset/catalog/2020influenza.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/2020influenza.pdf",
			title: "感染症・インフルエンザ対策2020",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日：2020年11月5日",
			catalog_list_info_update: "",
		},
		{
			alt: "食品用測定機器",
			image: "/asset/catalog/2020_shokuhin.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/shyokuhin.pdf",
			title: "食品用測定機器",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日：2020年5月28日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "アスファルト／コンクリート養生用温度計",
			image: "/asset/catalog/asphalt.png",
			link: "https://static.sksato.co.jp/corporate/catalog/asphalt.pdf",
			title: "アスファルト／コンクリート養生用温度計",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "掲載日：2020年2月3日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "防水放射温度計 SK-8950",
			image: "/asset/catalog/SK-8950.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-8950.pdf",
			title: "防水放射温度計 SK-8950",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日：2018年11月19日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "温度・湿度 JCSS校正のおしらせ",
			image: "/asset/catalog/JCSS.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/JCSS.pdf",
			title: "温度・湿度 JCSS校正のおしらせ",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2018年9月10日",
			catalog_list_info_update: "※2021年3月24日 最新版を掲載",
		},
		{
			alt: "防水キッチンタイマー TM-36",
			image: "/asset/catalog/TM-36.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/TM-36.pdf",
			title: "防水キッチンタイマー TM-36",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2018年8月21日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "黒球型携帯熱中症計 SK-180G",
			image: "/asset/catalog/SK-180GT.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-180GT.pdf",
			title: "黒球型携帯熱中症計 SK-180GT",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日:2018年7月4日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "無線温度・温湿度ロガー SK-L700Rシリーズ",
			image: "/asset/catalog/SK-L700R.png",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-L700R.pdf",
			title: "無線温度・温湿度ロガー SK-L700Rシリーズ",
			catalog_list_info_page: "A4 8ページ",
			catalog_list_info_date: "掲載日:2017年8月21日",
			catalog_list_info_update: "※2021年3月24日 最新版を掲載",
		},
		{
			alt: "精密型デジタル温度計 SK-810PT",
			image: "/asset/catalog/SK-810PT-02.png",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-810PT.pdf",
			title: "精密型デジタル温度計 SK-810PT",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "掲載日:2017年6月14日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "ノータッチタイマー「手洗い当番」TM-27",
			image: "/asset/catalog/TM-27.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/TM-27.pdf",
			title: "ノータッチタイマー「手洗い当番」TM-27",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2016年12月26日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "積算温度計 収穫どき SK-60AT-M",
			image: "/asset/catalog/SK-60AT-M.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-60AT-M.pdf",
			title: "積算温度計 収穫どき SK-60AT-M",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2016年9月21日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "PH計シリーズ",
			image: "/asset/catalog/PH_2016.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/PH.pdf",
			title: "PH計シリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "掲載日:2016年9月1日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "快適ナビプラス PC-5500TRH",
			image: "/asset/catalog/PC-5500TRH-TM-29.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/PC-5500TRH.pdf",
			title: "快適ナビプラス PC-5500TRH",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2015年12月7日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "ノータッチタイマー TM-29",
			image: "/asset/catalog/TM-29.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/TM-29.pdf",
			title: "ノータッチタイマー TM-29",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2015年12月7日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "屋外設置用防水ケース",
			image: "/asset/catalog/bousui-case.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/bousui-case.pdf",
			title: "屋外設置用防水ケース",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2015年11月17日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "デジタル温湿度計 PC-7980GTI",
			image: "/asset/catalog/PC-7980GTI.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/PC-7980GTI.pdf",
			title: "デジタル温湿度計 PC-7980GTI",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2015年6月17日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "手持屈折計Rシリーズ",
			image: "/asset/catalog/0180.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-100R.pdf",
			title: "手持屈折計Rシリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "掲載日:2015年5月29日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "デジタル気圧計 SK-500B",
			image: "/asset/catalog/sk-500b.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-500B.pdf",
			title: "デジタル気圧計 SK-500B",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日:2015年2月27日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "最高最低無線温湿度計 SK-300R",
			image: "/asset/catalog/sk-300r.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-300R.pdf",
			title: "最高最低無線温湿度計 SK-300R",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2015年1月5日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "K熱電対センサシリーズ",
			image: "/asset/catalog/K-netudenntui.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/K-netudentui.pdf",
			title: "K熱電対センサシリーズ",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日:2014年10月22日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "4ch温度ロガー SK-L400T",
			image: "/asset/catalog/sk-l400t.jpg",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-L400T.pdf",
			title: "4ch温度ロガー SK-L400T",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "掲載日:2014年6月19日",
			catalog_list_info_update: "※2021年3月24日 最新版を掲載",
		},
		{
			alt: "メモリ機能付き 防水型デジタル温度計 SK-1260",
			image: "/asset/catalog/SK-1260.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-1260.pdf",
			title: "メモリ機能付き 防水型デジタル温度計 SK-1260",
			catalog_list_info_page: "A4 xxページ",
			catalog_list_info_date: "掲載日:2011年3月22日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "デジタルパネルメータ EMシリーズ",
			image: "/asset/catalog/SK-EM-01.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-EM-01.pdf",
			title: "デジタルパネルメータ EMシリーズ",
			catalog_list_info_page: "A4 4ページ",
			catalog_list_info_date: "掲載日:2011年2月15日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "非接触温度計シリーズ・結露チェカーSK-130ITH",
			image: "/asset/catalog/infrared_thermo_catalog.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/infrared_thermo_catalog.pdf",
			title: "非接触温度計シリーズ・結露チェカーSK-130ITH",
			catalog_list_info_page: "A4 8ページ",
			catalog_list_info_date: "掲載日:2009年8月31日",
			catalog_list_info_update: "※2021年3月18日 最新版を掲載",
		},
		{
			alt: "食品用放射温度計 SK-8920",
			image: "/asset/catalog/sk8920.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-8920.pdf",
			title: "食品用放射温度計 SK-8920",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日:2008年7月18日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "赤外線放射温度計 SK-8940",
			image: "/asset/catalog/8266s_pdf.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-8940.pdf",
			title: "赤外線放射温度計 SK-8940",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日:2007年3月20日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "デジタル温湿度計 SK-110TRH II シリーズ",
			image: "/asset/catalog/sk100trh2s.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-110TRH2.pdf",
			title: "デジタル温湿度計 SK-110TRH II シリーズ",
			catalog_list_info_page: "A4 2ページ",
			catalog_list_info_date: "掲載日:2006年8月9日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "デジタル温湿度計 SK-140TRH",
			image: "/asset/catalog/sk-140TRHs.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-140TRH.pdf",
			title: "デジタル温湿度計 SK-140TRH",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2006年2月16日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
		{
			alt: "デジタル温湿度計 SK-120TRH",
			image: "/asset/catalog/sk-120TRH2s.gif",
			link: "https://static.sksato.co.jp/corporate/catalog/SK-120TRH.pdf",
			title: "デジタル温湿度計 SK-120TRH",
			catalog_list_info_page: "A4 1ページ",
			catalog_list_info_date: "掲載日:2006年2月16日",
			catalog_list_info_update: "※2021年3月17日 最新版を掲載",
		},
	];

	const { locale } = React.useContext(LocaleContext);
	const CatalogDownloadJa = () => {
		return (
			<div className="content-container catalog-download catalog-download-image-border">
				<div>
					<p>
						製品カタログは、PDFファイルにて提供しております。
						<br />
					</p>
				</div>
				<div>
					<p className="important-text">
						※ダウンロードいただけるパンフレットは作成時点でのデータが元になっております。
						<br />
						価格・消費税率・仕様等は現行と異なることがありますのでご注意ください。
					</p>
				</div>
				<div>
					<ul className="catalog-ul flex wrap">
						{CatalogList.map((CatalogItem, index) => {
							return (
								<CatalogDownloadList
									key={index}
									image={CatalogItem.image}
									alt={CatalogItem.alt}
									link={CatalogItem.link}
									title={CatalogItem.title}
									catalog_list_info_page={CatalogItem.catalog_list_info_page}
									catalog_list_info_date={CatalogItem.catalog_list_info_date}
									catalog_list_info_update={
										CatalogItem.catalog_list_info_update
									}
									catalog_list_sk_link={CatalogItem.catalog_list_sk_link}
									sk_link={CatalogItem.sk_link}
								/>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};
	const CatalogDownloadEn = () => {
		return (
			<div className="content-container catalog-download-en">
				<p className="catalog-download-title bold">GENERAL CATALOG No. 621</p>
				<div className="catalog-download-contents">
					<img
						src={`/asset/catalog/general-catalog.jpg`}
						alt="General Catalog No.621"
						width="113"
						height="160"
					/>
					<p>
						New complete No. 621 catalog in PDF format.
						<br />
						To download the file, click the PDF file logo on the right.
						<br />
						<LocalizedLink
							to="/support/catalog-download/sk/"
							className="skindex"
						>
							Click here
						</LocalizedLink>
						for page by page download section.
					</p>

					<a href="https://static.sksato.co.jp/corporate/catalog/2021_GeneralCatalog.pdf">
						<img src={`/asset/PDF_32.png`} alt="pdf" width="32" height="32" />
						<span>3.9MB</span>
					</a>
				</div>
			</div>
		);
	};
	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{catalogDownload}</h2>
					{locale === "ja" ? <CatalogDownloadJa /> : <CatalogDownloadEn />}
				</section>
			</article>
			<Footer />
		</>
	);
};

export default CatalogDownload;
